.status-check{
    position: relative;
    display: flex;
    flex-direction: column;
    .status-message{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        padding: 10px;
        top: 100px;
        ion-img{
           width: 88px;
        }
        p{
            color: var(--ion-color-primary);
            font-size: 27px;
            font-weight: 500;
            text-align: center;
            margin: 17px 0  ;
        }
    }
    .status-button{
        position: absolute;
        top: 400px;
        left: 0;
        right: 0;
        padding: 0 20px;
    }
}